jQuery(function($){
  var currentPage = window.location.href;
  var recipeCheck = currentPage.search('/recipe-category');
  if (recipeCheck !== -1) {
    var navmenu = $('#navmenu');
    var recipesLink = navmenu.find('a[href*="/recipes/"]');
    recipesLink.parent().addClass('current-menu-item');
  }

});
